window.isWTBLoaded = false;

async function loadScript(src, sat_id) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.setAttribute('data-dloc-satellite-id', sat_id);
  document.head.appendChild(script);
  await new Promise((resolve, reject) => {
    script.onload = resolve;
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
  });
}

async function handleClick(event) {
  const wtbuyConfig = JSON.parse(localStorage.getItem("wtbuyConfig"));
  if (!window.isWTBLoaded && wtbuyConfig) {
    await loadScript(`${wtbuyConfig.debrain_script}`, `${wtbuyConfig.data_dloc_satellite_id}`);
    window.isWTBLoaded = true;

    const clonedEvent = new event.constructor(event.type, event);
    const repeatEventInterval = setInterval(() => {
      event.target.dispatchEvent(clonedEvent);
      if (document.querySelector('.dloc-root') !== null) {
        clearInterval(repeatEventInterval);
      }
    }, 300)
  }
}

async function handleBuyNowClick(e) {
  const parent = e.target.parentElement;
  parent.querySelector('.debrain-buy-now-wrapper a.dloc-link').click();
}

function wtbCustomMenuClickHandler() {
  document.querySelectorAll('.dloc-link').forEach(el => {
    el.addEventListener('click', handleClick);
  });

  // WTB - short code alternate for buy now click
  document.querySelectorAll('.wtb-buy-now-img').forEach(el => {
    el.addEventListener('click', handleBuyNowClick);
  });
}

export {wtbCustomMenuClickHandler};
